<template>
  <swiper
    ref="projectsSwiper" :options="swiperOptions" class="swiper"
    :delete-instance-on-destroy="true"
    :cleanup-styles-on-destroy="true"
  >
    <swiper-slide v-for="el in news" :key="el.id" class="slider__slide">
      <NewsItem :news="el" @openNews="$emit('reLinkNews', el.id)"/>
    </swiper-slide>
    <div class="swiper-scrollbar" slot="pagination"></div>
  </swiper>
</template>

<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper';
import { mapState } from 'vuex';
import NewsItem from '../../../components/NewsItem.vue';

export default {
  name: 'NewsSlider',

  computed: {
    ...mapState('news', ['news']),
    ...mapState(['swiperOptions']),

    swiper() {
      return this.$refs.projectsSwiper.$swiper
    },
  },

  components: { NewsItem, Swiper, SwiperSlide },
}
</script>

<style scoped>
.slider__slide {
  width: 340px;
}
</style>
