<template>
  <section class="one-project fade">
    <Loader v-if="loading" />
    <div
      class="inline-grid-container container-padding page-block"
    >
      <LandingTitleBlock
        :title="oneNews.title"
        :pic_url="oneNews.pic_url"
        :data="oneNews.data"
        :tags="oneNews.tags"
        is-one-project
        line-height="50px"
      />
      <div
        class="project-wrapper"
        v-html="oneNews.text"
      >
      </div>
    </div>

    <News />

    <Feedback />

  </section>
</template>

<script>
import { mapActions, mapMutations } from 'vuex';
import Loader from '../../components/UI/Loader.vue';
import LandingTitleBlock from '../../components/LandingTitleBlock.vue';
import NewsSlider from './components/NewsSlider.vue';
import CustomLink from '../../components/UI/CustomLink.vue';
import { changeUrl } from '@/helpers/commonHelpers';
import Feedback from "@/components/Feedback.vue";
import News from "@/views/mainPage/components/News.vue";

export default {
  name: 'OneNews',

  data () {
    return {
      loading: true,
      oneNews: null,
    }
  },

  watch: {
    $route: async function (to, from) {
      this.loading = true
      this.oneNews = await this.getOneNews(this.$route.params.id)
      this.loading = false
    }
  },

  methods: {
    ...mapActions('news', ['getOneNews', 'getNews']),
    ...mapMutations(['setTag']),
    choiceHash (el) {
      this.setTag(el)
      this.$router.push('/news')
    },
    changeUrl,
  },

  async mounted () {
    this.oneNews = await this.getOneNews(this.$route.params.id)
    this.oneNews.text = changeUrl(this.oneNews.text)
    await this.getNews({ limit: 6 })
    this.loading = false
  },

  components: { Feedback, Loader, LandingTitleBlock, NewsSlider, CustomLink, News },
};
</script>

<style scoped lang="scss">
.one-project {
  margin-bottom: 140px;
}
.page-block {
  width: 100%;
}
.tags {
  display: flex;
  margin-top: 20px;
  p {
    color: blue;
    cursor: pointer;
    margin-right: 20px;
    line-height: 20px;
    transition: 0.3s;
    &:hover {
      text-decoration: underline;
      transition: 0.3s;
    }
  }
}

.project-slider {
  margin: 150px 90px 0 90px;
  padding-bottom: 120px;
}

.project-slider__title {
  display: flex;
  align-items: flex-end;
  margin: 0 0 30px 120px;
  h3 {
    margin-right: 10px;
    font-weight: 500;
    font-size: 23px;
    line-height: 30px;
  }
}

@media screen and (max-width: 1100px) {
  .project-slider {
    margin: 80px 25px 0 25px;
    padding-bottom: 70px;
  }
  .project-slider__title {
    display: flex;
    justify-content: space-between;
    margin: 0 0 30px 0;
  }
  .page-block {
    margin-bottom: 56px;
  }
}
</style>
